const solutionsData = [
    {
        id: 0,
        name: "Взыскание неустойки с собственников квартиры.",
        cause: "Причина залива: лопнувшая труба ГВС, подача на полотенцесушитель.",
        solution: {
            primaryText: "Решение суда: возмещен ущерб в размере ",
            amount: "300 800 руб. ",
            srcondaryText: "(включая стоимость восстановительного ремонта 293 800 руб., расходы на проведение оценки 5 000 руб., почтовые расходы 2 000 руб.)."
        },
        link: {
            url: "https://mos-gorsud.ru/rs/nagatinskij/services/cases/civil/details/e94dd541-b005-11ec-8ab1-558ff414d67d?caseNumber=2-3499/22",
            linkText: "Информация по делу на сайте Мосгорсуда.",
        },
        image: [
            "/images/solutions/Krugov-1.jpg",
            "/images/solutions/Krugov-2.jpg",
        ]
    },
    {
        id: 1,
        name: "Взыскание неустойки с собственника квартиры",
        cause: "Причина залива: протечка в квартире.",
        solution: {
            primaryText: "Решение суда: возмещен ущерб в размере ",
            amount: "346 677,33 руб. ",
            srcondaryText: "(включая стоимость восстановительного ремонта 304 433 руб., расходы на проведение оценки 6 000 руб., расходы на оплату услуг представителя 30 000 руб., расходы на оплату госпошлины 6 244,33 руб.)."
        },
        link: {
            url: "https://mos-gorsud.ru/rs/gagarinskij/services/cases/civil/details/94ffff40-0f2e-11ec-9f56-ed0f0e7160b1",
            linkText: "Информация по делу на сайте Мосгорсуда.",
        },
        image: [
            "/images/solutions/Mullahodzhaev.jpg"
        ]
    },
    {
        id: 2,
        name: "Гражданское дело о взыскании неустойки, излишне уплаченных денежных средств, судебных расходов, компенсации морального вреда",
        cause: "Взыскание неустойки по договорам",
        solution: {
            primaryText: "Решение суда: возмещен ущерб в размере ",
            amount: "386 985 руб. ",
            srcondaryText: "(включая неустойку по договору в размере 300 000 руб., неустойку по договору в размере 9 000 руб., сумму излишне уплаченных денежных средств по договору в размере 42 985,23 руб., компенсацию морального вреда в размере 5 000 руб., штраф в размере 30 000 руб., расходы на оплату услуг представителя в размере 25 000 руб.)"
        },
        link: {
            url: "https://odintsovo--mo.sudrf.ru/modules.php?name=sud_delo&srv_num=1&name_op=case&case_id=574557837&case_uid=2cbba1d5-a4ae-4a7d-981d-14f1e56725bd&delo_id=1540005",
            linkText: "Информация по делу на сайте Одинцовского городского суда Московской области.",
        },
        image: [
            "/images/solutions/Ashakov.jpg"
        ]
    },
];

export default solutionsData;
