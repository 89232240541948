const reviewsData = [
    {
        id: 0,
        reviewText: "Хочу выразить благодарность юристам Адриану Горбунову и Алексею Отинову за качественное и оперативное предоставление услуг. У меня возник спор с застройщиком и мне необходима была профессиональная юридическая помощь. В ходе работы Адриан и Алексей внимательно относились к моим вопросам, были всегда на связи и предоставляли всю необходимую информацию. При взаимодействии мне были даны рекомендации и советы, что помогло мне чувствовать себя уверенно и спокойно. По результатам судебных заседаний мне была выплачена неустойка и штраф. Спасибо Вам за помощь!",
        reviewAuthor: "Манукян А.С.",
        reviewAvatar: ""
    },
    {
        id: 1,
        reviewText: "В прошлом году соседи затопили мою квартиру (пострадали кухня, большая комната и коридор). Я обратилась к команде юридического центра «Просто». Юристы внимательно ознакомились с делом, в кратчайшие сроки произвели оценку ущерба.  Хочу отметить, что всю работу с документами и взаимодействие с виновниками юристы взяли на себя. Также юристы посещали все судебные заседания, и мне не нужно было думать об этом. Спасибо за профессиональную и качественную работу, за возможность делегировать решение неприятных вопросов, а в конце получить достойный результат! Я довольна взаимодействием и работой юристов!",
        reviewAuthor: "Ирина Телепина",
        reviewAvatar: "/images/reviews/telepina.jpg"
    },
    {
        id: 2,
        reviewText: "Хотела бы выразить благодарность и признательность за профессиональную помощь и поддержку, которую я получила от юриста Горбунова Адриана Борисовича. Мою ванную затопили соседи, я обратилась за помощью к юристу, он оперативно откликнулся, предложил варианты решения проблемы. Адриан Борисович помог в проведении оценки и защищал мои интересы в суде. Благодаря компетентности, опыту и настойчивости юриста, я смогла выиграть дело и добиться компенсации ущерба.",
        reviewAuthor: "Л.И. Саина",
        reviewAvatar: ""
    },
];

export default reviewsData